/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementDocumentService {
  constructor() {
    this._httpService = new HttpService('/sdi/document-movement');
  }

  async FindEnabled(requestBody) {
    return await this._httpService.get('/find-enable?' + requestBody);
  }

  async Get(params) {
    return await this._httpService.get(params);
  }

  async Post(payload) {
    return await this._httpService.post('', payload);
  }

  async Edit(payload) {
    return await this._httpService.put('', payload);
  }

  async Delete(movementDocumentId) {
    return await this._httpService.delete(`/${movementDocumentId}`);
  }
};

<template>
  <div>
    <v-row v-if="documentsList.length > 0 && showEditAndSaveButton" class="mt-4 pl-3 pr-11" justify="space-between">
      <div>
        <p class="title">Upload de Documentos</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSave()" :disabled="waitingDocumentUpload">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>

    <v-form ref="uploadFilesForm">
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loadingDocuments"
      />
      <v-row v-if="documentsList.length > 0" class="mt-4 mb-3 mr-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
        <v-col cols="12" lg="3" md="5" v-for="(item, index) in documentsList" :key="index">
          <label
            class="label">
            {{ item.description }}{{ fileRulesValidation(item).length > 0 ? '*' : '' }}
          </label>

          <v-row>
            <v-col>
              <v-file-input
                v-model="item.file"
                @change="uploadDocument(item)"
                placeholder="Clique para adicionar"
                accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                color="textPrimary"
                outlined
                :disabled="waitingDocumentUpload || isDisabledFields"
                :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                :loading="waitingDocumentUpload"
                :rules="fileUploadRulesValidation(item)"
                @click:clear="clearDocument(item)"
              >
                <template v-slot:selection="{}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        small
                        label
                        color="primary"
                        v-on="on"
                      >
                        {{ truncateText(item.file.name, 25) }}
                      </v-chip>
                    </template>
                    <span>{{ item.file.name }}</span>
                  </v-tooltip>
                </template>
              </v-file-input>
            </v-col>
            <v-col class="mb-7" align-self="center" v-if="showDownloadButton">
              <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
                Baixar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import MovementDocumentService from '@/services-http/sdi/MovementDocumentService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import Rules from '@/shared/validators/formRules';

export default {
  name: 'UploadDocumentComponent',
  data: () => ({
    documentsList: [],
    documentsData: [],
    movementDocuments: [],
    isDisabledFields: true,
    waitingDocumentUpload: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
    movementDocumentService: null,
    loadingDocuments: false,
  }),

  props: {
    showEditAndSaveButton: {
      required: false,
      type: Boolean,
    },
    useProps: {
      required: false,
      type: Boolean,
    },
    showDownloadButton: {
      default: true,
      type: Boolean,
    },
    updateParent: {
      default: false,
      type: Boolean,
    },
    movementType: {
      required: false,
    },
    beneficiaryType: {
      required: false,
    },
    documentParams: {
      required: false,
    },
    attachments: {
      required: false,
    },
    movementRecordId: {
      required: false,
    },
    startSearch: {
      type: Boolean,
      default: false
    },
    stopUpdate: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    attachments: {
      immediate: true,
      deep: true,
      async handler(attachments) {
        if (this.stopUpdate) return;

        if (!this.documentsList || this.documentsList.length === 0) return;

        this.attachmentsVerification(attachments);
      },
    },
    startSearch: {
      immediate: true,
      deep: true,
      async handler(start) {
        if (start) {
          await this.findDocumentMovement();
        }
      },
    }
  },

  methods: {
    async findDocumentMovement() {
      this.loadingDocuments = true;

      try {
        await this.loadDocumentMovements(this.documentParams);

        if (this.attachments && this.attachments.length > 0) {
          this.attachmentsVerification(this.attachments);
        }
      } finally {
        this.loadingDocuments = false;
        this.$emit("searchEnded");
      }
    },
    attachmentsVerification(attachments) {
      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment) => {
          const documentTypeIndex = this.documentsList.findIndex(
            (documentType) => documentType.id === attachment.documentTypeId
          );

          if (documentTypeIndex !== -1) {
            this.$set(this.documentsList[documentTypeIndex], 'file', {
              name: attachment
                && attachment.file
                && attachment.file.name
                  ? attachment.file.name
                  : attachment.titleDocument,
              idRecentlyUploaded: attachment.idRecentlyUploaded,
              id: attachment.id,
              file: attachment.file,
              type: attachment
                && attachment.file
                && attachment.file.type
                  ? attachment.file.type
                  : attachment.type,
            });
          }
        });
      }
    },
    validate() {
      if(this.documentsList) {
        this.documentsList.forEach(doc => {
          if(doc.mandatory && !doc.file){
            return false;
          }
        });
      }

      if (!this.$refs.uploadFilesForm.validate()) {
        this.isDisabledFields = false;

        return false
      }

      return true;
    },

    areObjectsDifferent(oldObject, newObject) {
      const oldKeys = oldObject ? Object.keys(oldObject) : [];
      const newKeys = newObject ? Object.keys(newObject) : [];

      if (oldKeys.length !== newKeys.length) {
        return true;
      }

      return oldKeys.some((key) => {
        const oldValue = oldObject[key];
        const newValue = newObject[key];

        if (typeof oldValue === "object" && typeof newValue === "object") {
          return this.areObjectsDifferent(oldValue, newValue);
        }

        return oldValue !== newValue;
      });
    },

    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },

    async loadDocumentMovements(documentParams) {
      if (!documentParams) return;

      let query = `movementType=${documentParams.movementType}&beneficiaryType=${documentParams.beneficiaryType}`;

      if (documentParams.carrierId) {
        query += `&insuranceCarrierId=${documentParams.carrierId}`;
      }

      if (documentParams.contractId) {
        query += `&parentContractId=${documentParams.contractId}`;
      }

      if (documentParams.kinshipId) {
        query += `&kinshipId=${documentParams.kinshipId}`;
      }

      if(documentParams.benefitTypeCode) {
        query += `&benefitTypeCode=${documentParams.benefitTypeCode}`;
      }

      if (!query) return

      const endpoint = `${query}`;

      try {
        const response = await new MovementDocumentService().FindEnabled(endpoint);

        let documentsMovements = response.data;

        if (!documentParams || documentParams && !documentParams.hasGracePeriod) {
          documentsMovements = documentsMovements.filter(
            (documentMovement) => documentMovement.gracePeriod === false);
        }

        this.documentsList = documentsMovements.map((element) => ({
          mandatory: element.mandatory,
          name: element.name,
          description: element.description,
          id: element.documentTypeId,
          file: null,
        }));

        const priorityConfiguration = this.findConfigurationByPriority(this.movementDocuments);

        await this.loadDocumentsByMovementRecordId();

        if (!priorityConfiguration || Object.keys(priorityConfiguration).length === 0) return;

        const documentListIndex = this.documentsList.findIndex(
          (document) => document.id === priorityConfiguration.documentType.id
          || document.documentTypeId === priorityConfiguration.documentType.id);

        if (documentListIndex === -1) return;

        this.$set(this.documentsList[documentListIndex], 'configuration', priorityConfiguration);

        this.$forceUpdate();
      } catch (error) {
        console.error('Erro ao carregar documentos:', error);
      }
    },

    fileRulesValidation(item) {
      if (item && Object.keys(item).length > 0) {
        if (item.mandatory)
          return [this.rule.required];
      }

      return [];
    },

    fileUploadRulesValidation(item) {
      if(!item.file && item.mandatory) {
        return [this.rule.required];
      }
      if (item.file) return [ this.rule.validateUploadFiles(item) ];
      return this.fileRulesValidation(item);
    },

    async uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && typeof item.file !== 'undefined') {
          await this.clearDocument(item);
          this.waitingDocumentUpload = true;
          const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
          await this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
            this.waitingDocumentUpload = false;

            this.documentsData.push({
              idRecentlyUploaded: response.data,
              id: response.data,
              name: item.name,
              file: item.file,
              type: item.file.type,
              documentTypeId: item.documentTypeId ? item.documentTypeId : item.id,
              titleDocument: item.file.name,
            });
            // eslint-disable-next-line no-param-reassign
            item.idRecentlyUploaded = response.data;
          }).catch(() => {
            this.waitingDocumentUpload = false;
          });

          if (this.updateParent) {
            if (this.stopUpdate) return;

            if (this.attachments && this.attachments.length > 0) {
              const parentAttachments = this.attachments.filter((attachment) => !this.documentsData.some((doc) => doc.idRecentlyUploaded === attachment.idRecentlyUploaded));

              if (parentAttachments && parentAttachments.length > 0) {
                parentAttachments.forEach((attachment) => {
                  this.documentsData.push({
                    idRecentlyUploaded: attachment.idRecentlyUploaded,
                    id: attachment.idRecentlyUploaded,
                    name: attachment.name,
                    file: attachment.file,
                    type: attachment.file.type,
                    documentTypeId: attachment.documentTypeId ? attachment.documentTypeId : attachment.id,
                    titleDocument: attachment.file.name,
                  })
                })
              }
            }

            this.$emit('observerDocumentData', this.documentsData);
          }
        } else {
          this.clearDocument(item);
        }
      }
    },

    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex(
            (document) => document.id === item.id || document.id === item.idRecentlyUploaded);

          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
            this.$emit('observerDocumentData', this.documentsData);
          }
        }
      }
    },

    async loadDocumentsByMovementRecordId() {
      if (this.movementRecordId) {
        if (this.$route.name === 'MovementDetail') {
          this.isDisabledFields = true;
        } else {
          this.isDisabledFields = false;
        }
        const queryString = `?movementRecordId=${this.movementRecordId}`;
        await this.documentService.FindDocumentByFilters(queryString)
          .then((response) => response.data)
          .then(async (result) => {
            if (result && result.length > 0 && this.documentsList.length > 0) {
              this.documentsList = this.documentsList.map((documentType) => {
                const matchingDocument = result.find(
                  (file) => file.documentType.id === documentType.id
                );

                if (matchingDocument) {
                  return {
                    ...documentType,
                    file: new File([matchingDocument.name], matchingDocument.name, { type: 'image/png' }),
                    documentTypeId: documentType.id,
                    id: matchingDocument.id,
                    name: matchingDocument.name,
                    loadingDownload: false,
                  };
                }

                return documentType;
              });
            }
            this.loadDocumentsInData();
          });
      }
    },

    async loadDocumentsInData() {
      this.documentsData = [];

      this.documentsList.forEach((document) => {
        if (document && document.documentTypeId) {
          this.documentsData.push(document);
        }
      });

      if (this.showEditAndSaveButton) {
        this.onClickSave();
      }
      this.$emit('observerDocumentData', this.documentsData);
    },

    resetDocuments() {
      this.documentsList = [];
      this.documentsData = [];
    },

    resetDocumentsList() {
      this.documentsList.forEach((document) => {
        this.clearDocument(document);
      });
    },

    findConfigurationByPriority(documents) {
      const priorityMandatory = documents.find(doc => doc.mandatory === true);

      if (priorityMandatory && Object.keys(priorityMandatory).length > 0) {
        return priorityMandatory;
      }
    },

    onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },

    onClickSave() {
      sessionStorage.setItem('documents', JSON.stringify(this.documentFormatted()));

      this.isDisabledFields = true;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },

    documentFormatted() {
      const documentFormatted = this.documentsList.filter((element) => element.file !== null && typeof element.file !== 'undefined').map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },

    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);

            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },

    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },

    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
  },

  async created() {
    this.documentService = new DocumentService();
    this.rule = new Rules();
  },

  async mounted() {
    this.isDisabledFields = false;
    this.verifyTypeOperationForMovement();
    if (this.showEditAndSaveButton) this.isDisabledFields = true;
  },

  beforeDestroy() {
    this.$emit("cleanThisDocuments")
  }
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-row class="mt-2">
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">CPF</label>
              <v-text-field
                v-model="dependent.cpf"
                v-mask="'###.###.###-##'"
                outlined
                color="textPrimary"
                :rules="dependent.cpf ? [rule.cpf] : []"
                validate-on-blur
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Nome</label>
              <v-text-field
                v-model="dependent.name"
                required
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.name ? [rule.name, rule.validateIsAlphanumeric] : []"
                @blur="dependent.name ? dependent.name = formatter.formatToTitleCase(dependent.name) : null"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Data de nascimento</label>
              <v-text-field
                v-model="dependent.birthDate"
                color="textPrimary"
                outlined
                :disabled="isDetail"
                @input="observerDependents()"
                append-icon="fas fa-calendar-alt"
                placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :rules="dependent.birthDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                validate-on-blur
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">UF de Nascimento</label>
              <v-autocomplete
                v-model="dependent.stateBirth"
                :items="states"
                item-text="label"
                item-value="label"
                outlined
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Gênero</label>
              <v-autocomplete
                v-model="dependent.gender"
                :items="propsGenders"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Nome da Mãe</label>
              <v-text-field
                v-model="dependent.motherName"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                validate-on-blur
                @blur="dependent.motherName ? dependent.motherName = formatter.formatToTitleCase(dependent.motherName) : null"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Grau de Parentesco</label>
              <v-autocomplete
                v-model="dependent.degreeKinship"
                :items="propsKinships"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :rules="[rule.required]"
                :disabled="isDetail"
                @input="observerDependents(); requestConfirmChangeKinship();"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">CNS</label>
              <v-text-field
                v-model="dependent.cns"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                @input="observerDependents()"
                v-mask="'###############'"
                :rules="dependent.cns ? [rule.cns] : []"
                validate-on-blur
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">DNV</label>
              <v-text-field
                v-model="dependent.dnv"
                v-mask="'##-########-#'"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.dnv ? [rule.dnv] : []"
                validate-on-blur
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Peso(kg)</label>
              <v-text-field
                v-mask="'###'"
                type="number"
                min="1"
                v-model="dependent.weight"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight); observerDependents()"
                @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                id="fieldCheckedWeight"
                outlined
                color="textPrimary"
                :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Altura(cm)</label>
              <v-text-field
                v-mask="'###'"
                type="number"
                min="1"
                v-model="dependent.height"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                @input="dependent.height = formatter.removeInvalidNumber(dependent.height); observerDependents()"
                @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                id="fieldCheckedHeight"
                outlined
                color="textPrimary"
                :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Estado Civil</label>
              <v-autocomplete
                :items="propsMaritalStatus"
                v-model="dependent.maritalStatus"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Complemento de Matrícula</label>
              <v-text-field
                v-model="dependent.registrationComplement"
                maxlength="50"
                outlined
                color="textPrimary"
              />
            </v-col>
          </v-row>
          <v-col cols="12">
            <label class="title">Dados de Contato</label>
            <v-row class="my-5">
              <v-col cols="12" md="3">
                <v-text-field
                  label="Telefone Residencial"
                  placeholder="Informe o número"
                  v-model="dependent.telephone"
                  lazy-validation
                  v-mask="'(##) ####-####'"
                  :rules="dependent.telephone ? [rule.telephone] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Celular"
                  placeholder="Informe o número"
                  v-model="dependent.cellphone"
                  v-mask="'(##) #####-####'"
                  :rules="dependent.cellphone ? [rule.cellphone] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="E-Mail"
                  placeholder="exemplo@email.com.br"
                  :rules="dependent.email ? [rule.email] : []"
                  v-model="dependent.email"
                  outlined
                  color="textPrimary"
                  validate-on-blur
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <label class="title">Identidade</label>
            <v-row class="my-5">
              <v-col cols="3">
                <label class="label">Natureza</label>
                <v-autocomplete
                  class="mt-2"
                  v-model="dependent.identityDocumentNature"
                  :items="identityDocumentNatureTypes"
                  :disabled="isDetail"
                  @input="dependent.identityDocumentNumber = null;"
                  placeholder="Informe a natureza do documento"
                  color="textPrimary"
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="3">
                <label class="label">Documento</label>
                <v-text-field
                  v-model="dependent.identityDocumentNumber"
                  :rules="dependent.identityDocumentNumber ? [rule.rgMaxDigit(dependent.identityDocumentNature, dependent.identityDocumentNumber)] : []"
                  validate-on-blur
                  v-mask="'NNNNNNNNNNNNNNN'"
                  class="mt-2"
                  placeholder="Informe o número do documento"
                  outlined
                  color="textPrimary"
                  :disabled="isDetail"
                />
              </v-col>
              <v-col cols="3">
                <label class="label">Data de Expedição</label>
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    v-model="dependent.identityDocumentEmissionDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    class="mt-2"
                    :disabled="isDetail"
                    :rules="dependent.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                  />
                </div>
              </v-col>
              <v-col cols="3">
                <label class="label">Orgão Emissor</label>
                <v-text-field
                  maxlength="8"
                  v-mask="'AAAAAAAA'"
                  @input="dependent.identityDocumentIssuer = dependent.identityDocumentIssuer.toUpperCase();"
                  v-model="dependent.identityDocumentIssuer"
                  class="mt-2"
                  placeholder="Informe o órgão emissor"
                  outlined
                  color="textPrimary"
                  :disabled="isDetail"
                />
              </v-col>
            </v-row>
            <v-row class="my-5">
              <v-col cols="3">
                <label class="label">UF Emissor</label>
                <v-autocomplete
                  v-model="dependent.identityDocumentState"
                  :items="states"
                  class="mt-2"
                  item-text="label"
                  item-value="label"
                  outlined
                  placeholder="UF emissor"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  :disabled="isDetail"
                  clearable
                />
              </v-col>
              <v-col cols="3">
                <label class="label">País Emissor</label>
                <v-autocomplete
                  v-model="dependent.identityDocumentCountry"
                  :disabled="isDetail"
                  :items="country"
                  class="mt-2"
                  item-text="text"
                  item-value="value"
                  outlined
                  placeholder="País emissor"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
            </v-row>
          </v-col>
          <div ref="addressInformationComponent">
            <AddressInformations
              class="pt-0"
              :addressData="dependent.address"
              :showHeaderPage="true"
              :isFreeMovement="false"
              :isDetail="true"
              @updateAddressData="updateAddressData"
            />
          </div>
          <div
            ref="uploadDocumentComponent"
            v-show="documentsVisibility">
            <div>
              <p class="title">Upload de Documentos</p>
            </div>

            <p
              class="text-h6 wineLabel--text">
              {{ dependent.contractName }}
            </p>

            <UploadDocuments
              ref="UploadDocuments"
              :showEditAndSaveButton="false"
              :showDownloadButton="true"
              :updateParent="true"
              :beneficiaryType="'DEPENDENT'"
              :documentParams="documentParams"
              :movementRecordId="dependent.id"
              @observerDocumentData="dependent.documents = $event"
              :startSearch="startSearch"
              @searchEnded="checkAllUploadDocuments"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmationModal
      ref="ConfirmationModal"
      @confirm="confirmChangeKinship"
      @cancel="cancelChangeKinship"
      :showCloseButton="false"
      :cancelLabel="'Cancelar'"
			:title="'Atenção'"
			:description="'Ao modificar o Grau de Parentesco, todos\
                    os documentos anexados que não estiverem relacionados\
                    ao novo grau poderão ser removidos.<br><br>\
                    Deseja continuar com essa alteração?'"
      :color="'primary'"
    />

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import DocumentService from '@/services-http/sdi/DocumentService';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  data: () => ({
    controlHeight: false,
    controlWeight: false,
    waitingDocumentUpload: false,
    documentParams: {
      contractId: null,
      carrierId: null,
      hasGracePeriod: false,
      movementType: null,
      beneficiaryType: null,
      kinshipId: null,
      benefitTypeCode: null
    },
    startSearch: false,
    documentsVisibility: false,
  }),
  components: {
    AddressInformations,
    UploadDocuments,
    ConfirmationModal,
    SnackbarCustomize,
  },

  mixins: [
    ConstantsMixin,
  ],

  props: {
    dependent: Object,
    isDetail: Boolean,
    propsGenders: Array,
    propsMaritalStatus: Array,
    propsKinships: Array,
    holder: {
      required: false,
    },
  },

  watch: {
    'dependent.degreeKinship': {
      deep: true,
      handler(newKinshipId, oldKinshipId) {
        if (this.confirmingChangeKinship) return;

        this.oldKinshipId = oldKinshipId;
        this.newKinshipId = newKinshipId;
      }
    }
  },

  methods: {
    checkAllUploadDocuments() {
      this.startSearch = false;

      const docComponentReference = this.$refs.UploadDocuments;

      this.documentsVisibility = docComponentReference
        && docComponentReference.documentsList.length > 0;
    },
    requestConfirmChangeKinship() {
      this.$refs.ConfirmationModal.open();

      setTimeout(() => {
        this.confirmingChangeKinship = true;
      }, 500);
    },
    cancelChangeKinship() {
      this.dependent.degreeKinship = this.oldKinshipId;

      setTimeout(() => {
        this.confirmingChangeKinship = false;
      }, 500);
    },
    async confirmChangeKinship() {
      this.dependent.degreeKinship = this.newKinshipId;

      setTimeout(() => {
        this.confirmingChangeKinship = false;
      }, 500);

      await this.loadDocumentMovements();
    },
    async loadDocumentMovements() {
      this.dependent.documents = [];

      this.documentParams.kinshipId = this.dependent.degreeKinship;

      this.startSearch = true;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
    setDocuments(plan, documents) {
      plan.documents = documents;
    },
    updateAddressData(data) {
      if(!data) return;
      this.dependent.address = {};
      this.dependent.address.code = data.code;
      this.dependent.address.log = data.log;
      this.dependent.address.city = data.city;
      this.dependent.address.region = data.region;
      this.dependent.address.logType = data.logType;
      this.dependent.address.country = data.country;
      this.dependent.address.neighborhood = data.neighborhood;
      this.dependent.address.number = data.number;
      this.dependent.address.complement = data.complement;
      this.dependent.address.ibgeCode = data.ibgeCode;
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    async uploadDocument(dependent, item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && typeof item.file !== 'undefined') {
          await this.replaceDocument(dependent, item);
          this.waitingDocumentUpload = true;
          const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
          this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
            this.waitingDocumentUpload = false;
            dependent.documents.push({
              id: response.data,
              name: item.name,
              file: item.file,
              type: item.file.type,
            });
            // eslint-disable-next-line no-param-reassign
            item.idRecentlyUploaded = response.data;
          });
          this.observerDependents();
        } else {
          await this.clearDocument(dependent, item);
        }
      }
    },
    async clearDocument(dependent, item) {
      if (dependent && item) {
        if (dependent.documents && dependent.documents.length > 0) {
          const documentIndex = dependent.documents.findIndex((document) => document.id === item.id);
          if (documentIndex >= 0) {
            dependent.documents.splice(documentIndex, 1);
            this.observerDependents();
          }
          const idDocument = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
          await this.deleteDocument(idDocument);
        }
      }
    },
    async replaceDocument(dependent, item) {
      if (dependent && item) {
        if (dependent.documents && dependent.documents.length > 0) {
          const documentIndex = dependent.documents.findIndex((document) => document.documentTypeId === item.documentTypeId);
          if (documentIndex >= 0) {
            dependent.documents.splice(documentIndex, 1);
          }
        }
      }
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    observerDependents() {
      const { dependent } = this;
      this.$emit('observerDependents', dependent);
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.documentService = new DocumentService();
  },

  beforeMount() {
    this.documentParams.beneficiaryType = this.dependent.beneficiaryType;
    this.documentParams.movementType = this.dependent.movementType;
    this.documentParams.kinshipId = this.dependent.degreeKinship;
    this.documentParams.carrierId = this.dependent.insuranceCarrierId;
    this.documentParams.benefitTypeCode = this.dependent.benefitType;
    this.documentParams.contractId = this.dependent.contractId;
    this.startSearch = true;
  },
});
</script>
